export enum MessageTemplateType {
    PackagingStatement = 0,
    OwnerDamageReportEmail = 1,
    ShipmentDelayAlert = 2,
    SharePODReceipt = 3,
    SharePackagingTransaction = 4,
    ShareDamageReport = 5,
}

export interface MessageTemplate {
    type: MessageTemplateType
    emailSubject: string
    emailBody: string
    smsBody: string
}
