<script setup lang="ts">
import { computed, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRoute } from 'vue-router'

import { ActivityLog, ActivityLogIcon, ActivityLogType } from '@/types/activity-log'
import { logConfig, translationsForFields } from '@/utils/activity-log'
import { datetime } from '@/utils/dates'
import { useAuthStore } from '@/stores/auth-store'

import ActivityLogItemData from '@/components/settings/activity-log/ActivityLogItemData.vue'
import MyButton from '@/components/my-components/MyButton.vue'

const props = defineProps<{
    log: ActivityLog
    inModal?: boolean
}>()
const authStore = useAuthStore()
const { t } = useI18n()
const route = useRoute()

const advancedDataLogs = reactive<Record<string, boolean>>({})

const logDetails = computed(() => logConfig[props.log.type])
const showDataFields = computed(() => {
    if (logDetails.value.icon === ActivityLogIcon.Created) return false
    if (logDetails.value.icon === ActivityLogIcon.Removed) return false
    if (logDetails.value.icon === ActivityLogIcon.Reorder) return false
    return true
})

const fields = computed(() => {
    if (!props.log.data?.fields) {
        return []
    }

    return Object.keys(props.log.data.fields)
        .map((field) => {
            if (field in translationsForFields) return t(translationsForFields[field])!

            const camelCaseField = field.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
            return t(camelCaseField)
        })
        .join(', ')
})

const licenseGuard = computed(() => {
    const moduleLicense = logDetails.value.moduleLicense

    if (!moduleLicense) return true
    if (moduleLicense && authStore.hasLicense(moduleLicense)) return true
    return false
})

const routeToEntity = computed(() => {
    const route = logDetails.value.route
    if (typeof route !== 'function') {
        return route
    }
    return route(props.log)
})

const logResourceName = computed(() => {
    if (props.log.data?.name) return props.log.data.name
    if (props.log.data?.fields?.name) return props.log.data.fields.name as string
    if (linkedTours.value.length > 0) return linkedTours.value.length + ' ' + t('tours')

    return ''
})

const showAdvancedData = computed(() => {
    if (props.log.type === ActivityLogType.DeliveryManagementShipmentsScannedOnTour) return false
    if (props.log.type === ActivityLogType.DeliveryManagementShipmentsScannedOnOtherTour)
        return false
    if (props.log.type === ActivityLogType.SettlementTemplateUpdated) return false
    if (logDetails.value.icon === ActivityLogIcon.Updated) return true
    return false
})

const linkedTours = computed(() => {
    if (props.log.data?.previousTourIds) {
        return props.log.data.previousTourIds
    }

    if (props.log.data?.newTourId) {
        return [props.log.data.newTourId]
    }

    return []
})

const linkedToursText = computed(() => {
    if (props.log.type === ActivityLogType.DeliveryManagementShipmentsScannedOnTour) {
        return t('viewPreviousTour')
    } else if (props.log.type === ActivityLogType.DeliveryManagementShipmentsScannedOnOtherTour) {
        return t('viewNewTour')
    }

    return t('view')
})

onMounted(() => {
    if (showAdvancedData.value && props.inModal !== true) {
        advancedDataLogs[props.log.id] = true
    }
})
</script>

<template>
    <div v-if="logDetails" class="flex justify-between items-center mx-4 mt-4 mb-1">
        <div class="flex">
            <div class="mr-2 self-center">
                <mdi:plus-thick v-if="logDetails.icon === ActivityLogIcon.Created" />
                <mdi:clipboard-edit-outline v-if="logDetails.icon === ActivityLogIcon.Updated" />
                <mdi:shuffle-variant v-if="logDetails.icon === ActivityLogIcon.Reorder" />
                <mdi:trash-can v-if="logDetails.icon === ActivityLogIcon.Removed" />
            </div>
            <div class="flex space-x-2 items-center">
                <h3
                    class="text-lg font-bold uppercase"
                    :class="`text-${logDetails.color}`"
                    v-text="t(logDetails.name, { entity: t(logDetails.entity) })"
                />
            </div>
        </div>
        <div class="flex items-center">
            <span class="text-green-400 text-lg uppercase font-bold" v-text="logResourceName" />
            <div v-if="licenseGuard && routeToEntity && !inModal" class="self-center">
                <RouterLink :to="routeToEntity" class="self-center mx-2">
                    <MyButton
                        v-tooltip="t('showEntity', { entity: t(logDetails.entity) })"
                        plain
                        scheme="primary"
                        size="micro"
                        icon
                    >
                        <div class="flex items-center">
                            <mdi:eye class="m-2" />
                        </div>
                    </MyButton>
                </RouterLink>
            </div>
        </div>
    </div>
    <div
        v-if="logDetails"
        class="w-full flex border-b-4 border-dashed border-primary-100 pb-5 px-4 last:border-b-0 dark:border-dark-600"
    >
        <div class="flex flex-col w-1/3 mt-1.5">
            <div>
                <div class="flex flex-col space-y-3">
                    <div class="flex text-xs items-center">
                        <mdi:clock-outline class="mr-1 self-center h-4 w-4" />
                        <span class="font-semibold capitalize" v-text="datetime(log.createdAt)" />
                    </div>

                    <div class="text-xs flex items-center">
                        <mdi:account class="mr-1 self-center h-4 w-4" />
                        <span class="capitalize font-semibold" v-text="log.user.name" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-2/3">
            <!-- Fields Updated: -->
            <div v-if="!advancedDataLogs[log.id]" class="flex flex-col justify-center items-end">
                <code
                    v-if="linkedTours.length > 0"
                    class="w-fit rounded-lg bg-primary-100 p-4 font-mono dark:bg-dark-600 text-xs flex flex-col"
                >
                    <RouterLink
                        v-for="tourId in linkedTours"
                        :key="tourId"
                        :to="{
                            name: 'dm.tours.show',
                            params: { tourId: tourId },
                            query: route.query,
                        }"
                    >
                        <span
                            class="font-semibold capitalize hover:text-primary-300"
                            v-text="linkedToursText"
                        />
                    </RouterLink>
                </code>
                <code
                    v-else-if="showDataFields && fields"
                    class="w-fit rounded-lg bg-primary-100 p-4 font-mono dark:bg-dark-600"
                >
                    <div class="text-xs">
                        <p class="font-semibold capitalize" v-text="fields" />
                    </div>
                </code>
            </div>

            <ActivityLogItemData v-if="advancedDataLogs[log.id]" :log="log" />
        </div>
    </div>
</template>
