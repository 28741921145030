<script lang="ts">
interface Props {
    modelValue: boolean
    hubId: uuid
}
</script>

<script setup lang="ts">
import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'

import axios from 'axios'
import { watch, ref, onMounted, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownStringOption } from '@/types/inputs'
import { Hub } from '@/types/delivery-management'
import { LicenseType } from '@/types/company'

import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

interface Form {
    haulierIds: uuid[]
    shipperLocationId: uuid | null
    packagingResponsibleLocationId: uuid | null
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    haulierIds: [],
    shipperLocationId: null,
    packagingResponsibleLocationId: null,
})

const hauliers = ref<DropdownStringOption[]>([])
const shipperLocations = ref<DropdownStringOption[]>([])
const hub = ref<Hub>()
const packagingResponsibleLocations = ref<DropdownStringOption[]>([])
const packagingResponsibleCompanyId = ref<uuid | null>(null)

async function fetchHub() {
    const response = await axios.get<ResourceResponse<Hub>>(
        window.route('dm.company.hubs.show', {
            company: authStore.companyId,
            hub: props.hubId,
        }),
    )

    hub.value = response.data.data

    data.shipperLocationId = hub.value.shipperLocation?.id ?? null
    data.haulierIds.push(...hub.value.hauliers.map((h) => h.id))
    packagingResponsibleCompanyId.value = hub.value.packagingResponsibleLocation?.companyId ?? null
    await nextTick()
    data.packagingResponsibleLocationId = hub.value.packagingResponsibleLocation?.id ?? null
}

async function fetchHauliers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
            haulier: true,
        }),
    )
    hauliers.value = response.data.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }))
}

async function fetchShipperLocations() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', {
            company: authStore.companyId,
        }),
    )
    shipperLocations.value = response.data.map((location) => ({
        value: location.id,
        label: location.name,
    }))
}

async function fetchResponsibleCompanyLocations() {
    if (!packagingResponsibleCompanyId.value) return

    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', {
            company: packagingResponsibleCompanyId.value,
        }),
    )
    packagingResponsibleLocations.value = response.data.map((location) => ({
        value: location.id,
        label: location.name,
    }))
}

async function onSubmit() {
    const response = await submit(
        'put',
        window.route('dm.company.hubs.update-shipper-hub', {
            company: authStore.companyId,
            hub: props.hubId,
            haulierIds: data.haulierIds ?? [],
        }),
    )
    if (response !== undefined) {
        emit('close')
        emit('saved')
    }
}

onMounted(() => {
    if (props.modelValue) {
        fetchHub()
        fetchHauliers()
        fetchShipperLocations()
    }
})

watch(
    () => props.hubId,
    () => fetchHub(),
)

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        reset()
    },
)

watch(packagingResponsibleCompanyId, () => {
    data.packagingResponsibleLocationId = null
    fetchResponsibleCompanyLocations()
})
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="600" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ t('updateShipperHub') }}
        </template>
        <MyForm class="space-y-4" :errors="errors" @submit.prevent="onSubmit">
            <MySelect
                v-model="data.shipperLocationId"
                :options="shipperLocations"
                :label="t('hubsLocation')"
                searchable
            />

            <MySelect
                v-model="data.haulierIds"
                name="haulierIds"
                :label="t('hauliers')"
                clear-button
                :options="hauliers"
                searchable
                multiple
            />

            <div
                v-if="
                    hub?.company.id !== hub?.shipperCompany?.id &&
                    authStore.hasLicense(LicenseType.PackagingModule)
                "
                class="flex w-full justify-between space-x-4"
            >
                <MySelect
                    v-model="packagingResponsibleCompanyId"
                    group-class="w-full"
                    clear-button
                    :options="hauliers"
                >
                    <template #label>
                        <div class="inline-flex items-center space-x-1">
                            <MyInputLabel>{{ t('packagingResponsibleCompany') }}</MyInputLabel>
                            <mdi:information v-tooltip="t('packagingResponsibleExplanation')" />
                        </div>
                    </template>
                </MySelect>

                <MySelect
                    v-model="data.packagingResponsibleLocationId"
                    :label="t('location')"
                    group-class="w-full"
                    name="packagingResponsibleLocationId"
                    clear-button
                    :options="packagingResponsibleLocations"
                    :disabled="!packagingResponsibleCompanyId"
                />
            </div>

            <div class="mt-4 flex justify-end gap-6">
                <MyButton :disabled="loading" scheme="primary" v-text="t('update')" />
            </div>
        </MyForm>
    </MyModal>
</template>
