<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import axios from 'axios'
import { v4 } from 'uuid'
import { notify } from '@kyvg/vue3-notification'

import { useAuthStore } from '@/stores/auth-store'
import {
    CompanyTagType,
    Deviation,
    DeviationLogType,
    MinimalCompanyTag,
} from '@/types/delivery-management'
import useForm from '@/hooks/use-form'
import { FileAttachment, ResourceResponse, uuid } from '@/types/general'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { DropdownStringOption } from '@/types/inputs'
import { cloudflareUpload, vaporUpload } from '@/utils/upload'
import router from '@/router'

import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'

interface Props {
    modelValue: boolean
    deviation: Deviation
}

interface TagOption extends DropdownStringOption {
    name: CompanyTagType
}

interface Form {
    note: string
    files: FileAttachment[]
    tags: uuid[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'deviationEscalated', ticketId: uuid): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()

const { data, loading, errors, reset, submit } = useForm<Form>({
    note: '',
    files: [],
    tags: [],
})

const addedDeviationFiles = ref<string[]>([])
const filesUploading = ref<uuid[]>([])
const tags = useLazyLoadedList<TagOption>(fetchTags)

const deviationFiles = computed<FileAttachment[]>(() => {
    return props.deviation.logs.flatMap((log) => log.files)
})

async function fetchTags(): Promise<TagOption[]> {
    const response = await axios.get<ResourceResponse<MinimalCompanyTag[]>>(
        window.route('dm.company.tags.index', { company: props.deviation.tour.company.id }),
    )

    return response.data.data.map((tag) => ({
        value: tag.id,
        name: tag.name,
        label: t(tag.name),
    }))
}

function toggleDeviationFile(file: FileAttachment) {
    if (addedDeviationFiles.value.includes(file.path)) {
        addedDeviationFiles.value = addedDeviationFiles.value.filter((f) => f !== file.path)
    } else {
        addedDeviationFiles.value.push(file.path)
    }
}

function removeFile(file: FileAttachment) {
    data.files = data.files.filter((f) => f !== file)
}

function uploadFiles(files: File[]) {
    for (const file of files) {
        const id = v4()
        const isImage = new RegExp('image/*').test(file.type)
        const path = isImage ? URL.createObjectURL(file) : ''
        data.files.push({
            id: id,
            type: 0,
            name: file.name,
            path: path,
            note: '',
        })
        uploadFile(file, id)
    }
}

async function uploadFile(file: File, fileId: uuid) {
    filesUploading.value.push(fileId)
    const isImage = new RegExp('image/*').test(file.type)

    try {
        let filepath = ''
        if (isImage) {
            filepath = await cloudflareUpload(file)
        } else {
            const response = await vaporUpload(file)
            filepath = response.key
        }

        data.files = data.files.map((file) => {
            if (file.id === fileId) {
                return { ...file, path: filepath }
            }

            return file
        })
    } catch {
        notify({ title: t('unknownError'), type: 'error' })
    } finally {
        filesUploading.value = filesUploading.value.filter((id) => id !== fileId)
    }
}

async function onSubmit() {
    const formData = { ...data, files: [...data.files] }

    for (const file of deviationFiles.value) {
        if (addedDeviationFiles.value.includes(file.path)) {
            formData.files.push({
                id: v4(),
                type: null,
                name: file.name,
                path: file.path,
                note: file.note,
            })
        }
    }

    const response = await submit<{ id: uuid }>(
        'POST',
        window.route('dm.company.deviations.escalate', {
            company: authStore.companyId,
            deviation: props.deviation.id,
        }),
        { data: formData },
    )

    if (response !== undefined) {
        router.push({
            name: 'dm.tickets.show',
            params: { ticketId: response.id },
        })
    }
}

watch(
    () => props.modelValue,
    async () => {
        if (!props.modelValue) return

        const creationLog = props.deviation.logs.find(
            (log) => log.type === DeviationLogType.Created,
        )

        reset({ files: [], note: creationLog?.note ?? '' })

        addedDeviationFiles.value = deviationFiles.value.map((file) => file.path)

        // We'll find tag's by name as the id's will not be the same between shipper and company on the tour
        await tags.fetch()
        const deviationTagNames = props.deviation.tags.map((tag) => tag.name)
        data.tags = tags.items.value
            .filter((tag) => deviationTagNames.includes(tag.name))
            .map((tag) => tag.value ?? '')
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <LoaderWrapper :visible="loading" />

        <template #title>
            <ContentHeading class="text-xl font-bold" v-text="t('escalateDeviation')" />
        </template>

        <MyForm class="mt-2 space-y-4" :errors="errors" @submit.prevent="onSubmit">
            <MySelect
                v-model="data.tags"
                :label="t('tags')"
                :options="tags.items.value"
                :loading="tags.fetching.value"
                name="tags"
                multiple
                searchable
                @focus="tags.fetch"
            />

            <div>
                <MyInputLabel v-text="t('files')" />

                <div class="grid grid-cols-4 gap-3">
                    <MyFileAttachment
                        v-for="file in deviationFiles"
                        :key="file.id"
                        class="cursor-pointer"
                        :file="file"
                        @click="toggleDeviationFile(file)"
                    >
                        <template #overlay>
                            <div class="absolute w-full h-full flex items-center justify-center">
                                <div
                                    v-if="addedDeviationFiles.includes(file.path)"
                                    class="text-xs rounded-lg text-white cursor-pointer py-2 px-3 z-10 bg-primary-500/95"
                                    v-text="t('selected')"
                                />
                            </div>
                        </template>
                    </MyFileAttachment>

                    <MyFileAttachment
                        v-for="file in data.files"
                        :key="file.id"
                        :file="file"
                        :uploading="filesUploading.includes(file.id)"
                        removable
                        @on-remove-clicked="removeFile(file)"
                    />

                    <MyFileSelect
                        multiple
                        class="h-full min-h-[8rem] rounded-xl"
                        type="image/*,application/pdf"
                        @selected:multiple="uploadFiles"
                    >
                        <mdi:plus class="text-2xl font-bold dark:text-gray-300" />
                    </MyFileSelect>
                </div>
            </div>

            <MyTextarea v-model="data.note" name="note" :label="t('comment')" />

            <div class="flex justify-end">
                <div
                    v-tooltip="{
                        disabled: filesUploading.length === 0,
                        content: t('filesAreUploading'),
                    }"
                >
                    <MyButton
                        :disabled="filesUploading.length > 0"
                        scheme="primary"
                        v-text="t('escalate')"
                    />
                </div>
            </div>
        </MyForm>
    </MyModal>
</template>
