import { ComposerTranslation } from 'vue-i18n'

import { ActivityLogDropdownOption, ActivityLogType } from '@/types/activity-log'
import { logConfig, translateLogType } from '@/utils/activity-log'
import { LicenseType } from '@/types/company'

/**
 * Groups the log types by module and returns them as dropdown options
 */

export function useModuleLogTypes(
    t: ComposerTranslation,
): Partial<Record<LicenseType | 'no-module', ActivityLogDropdownOption[]>> {
    const logTypes: Partial<Record<LicenseType | 'no-module', ActivityLogDropdownOption[]>> = {}

    Object.entries(logConfig).forEach(([logType, config]) => {
        const module = config.moduleLicense || 'no-module'
        if (!logTypes[module]) {
            logTypes[module] = []
        }
        logTypes[module].push(translateLogType(t, logType as unknown as ActivityLogType))
    })

    return logTypes
}
