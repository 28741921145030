<script lang="ts" setup>
import { computed, watch } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { notify } from '@kyvg/vue3-notification'

import { DropdownStringOption } from '@/types/inputs'
import { ResourceResponse } from '@/types/general'
import { CompanyTagType, MinimalCompanyTag } from '@/types/delivery-management'
import { useAuthStore } from '@/stores/auth-store'
import useForm from '@/hooks/use-form'

import MyForm from '@/components/my-components/form/MyForm.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyModal from '@/components/my-components/MyModal.vue'

interface Props {
    modelValue: boolean
}

interface Form {
    tags: CompanyTagType[]
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, loading, reset } = useForm<Form>({ tags: [] })

const tags = computed<DropdownStringOption[]>(() => {
    return Object.values(CompanyTagType).map((value) => ({
        value: value,
        label: t(value),
    }))
})

async function onSubmit() {
    const response = await submit(
        'PUT',
        window.route('dm.company.tags.update', {
            company: authStore.companyId,
        }),
    )
    if (response) {
        emit('update:modelValue', false)
        notify({ type: 'success', text: t('companyTagsUpdated') })
    } else {
        notify({ type: 'error', text: t('formError') })
    }
}

async function fetchTags() {
    loading.value = true
    const response = await axios.get<ResourceResponse<MinimalCompanyTag[]>>(
        window.route('dm.company.tags.index', {
            company: authStore.company,
        }),
    )
    data.tags = response.data.data.map((tag) => tag.name)
    loading.value = false
}

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue) {
            fetchTags()
            return
        }
        reset()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <LoaderWrapper :visible="loading" />
        <template #title>
            {{ t('manageEntity', { entity: t('tags') }) }}
        </template>
        <MyForm :errors="errors" class="flex flex-col space-y-3 mt-1" @submit.prevent="onSubmit()">
            <MySelect v-model="data.tags" :options="tags" multiple clear-button name="tags" />
            <MyButton
                scheme="primary"
                class="mt-3 ml-auto"
                :disabled="loading"
                v-text="t('updateEntity', { entity: 'tags' })"
            />
        </MyForm>
    </MyModal>
</template>
