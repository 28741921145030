<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import dayjs from 'dayjs'

import { ActivityLog } from '@/types/activity-log'
import { logConfig, translateAndFormatFields, translationsForFields } from '@/utils/activity-log'
import { datetime } from '@/utils/dates'

const { t } = useI18n()

const props = defineProps<{
    log: ActivityLog
}>()

const isPreferenceLog = computed(() => {
    return logConfig[props.log.type].entity.includes('Preference')
})

function validateDate(value: string): boolean {
    // This is to ensure that the value is as long as a date, since the numbers "233" or "1002" would still pass the date validation
    return value.length >= 19 && dayjs(value).isValid()
}

function formatKey(key: string): string {
    if (typeof key !== 'string') return key
    if (key in translationsForFields) return t(translationsForFields[key])
    return key.split('_').join(' ')
}

function parseValue(value: number | string | boolean): string {
    if (typeof value === 'string') {
        if (validateDate(value)) {
            return datetime(value)
        }
        return value
    }
    if (typeof value === 'boolean') return value ? t('yes') : t('no')
    if (isPreferenceLog.value) {
        switch (value) {
            case 0:
                return t('required')
            case 1:
                return t('optional')
            case 2:
                return t('hidden')
            default:
                return ''
        }
    } else {
        return value.toString()
    }
}

function parseField(data: Record<number | string, unknown>): string {
    if (Array.isArray(data)) {
        return data.length as unknown as string
    }

    if (typeof data !== 'object') {
        return parseValue(data)
    }

    for (const key in data) {
        const value = data[key]

        if (value !== null) {
            if (typeof value === 'object') {
                parseField(value as Record<number | string, unknown>)
            } else {
                data[key] = parseValue(value as number | string | boolean)
            }
        }
    }

    if (data !== null && 'fields' in data) {
        const fields = data['fields'] as Record<string, unknown>
        delete data['fields']
        Object.assign(data, fields)
    }

    return translateAndFormatFields(t, data)
}
</script>

<template>
    <div v-if="log.data" class="font-mono text-sm">
        <div class="flex space-x-4">
            <!-- Previous Values -->
            <div class="flex flex-col w-1/2">
                <span class="text-red-400 font-semibold" v-text="t('previousValues')" />
                <code
                    class="block w-full break-words rounded-lg bg-primary-100 p-4 font-mono dark:bg-dark-600"
                >
                    <div v-if="log.previousData !== null">
                        <div
                            v-for="(field, key) in log.previousData?.fields"
                            :key="key"
                            class="mb-1"
                        >
                            <div>
                                <span class="font-semibold capitalize" v-text="formatKey(key)" />

                                <span v-text="':'" />
                                <span
                                    class="ml-1 whitespace-pre-line"
                                    v-text="parseField(field as {})"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <span v-text="t('noPreviousValues')" />
                    </div>
                </code>
            </div>

            <mdi:arrow-right-bold class="self-center mt-4" />

            <!-- New Values -->
            <div class="flex flex-col w-1/2">
                <span class="text-green-400 font-semibold" v-text="t('newValues')" />
                <code
                    class="block w-full break-words rounded-lg bg-primary-100 p-4 font-mono dark:bg-dark-600"
                >
                    <div>
                        <div v-for="(field, key) in log.data.fields" :key="key" class="mb-1">
                            <div>
                                <span class="font-semibold capitalize" v-text="formatKey(key)" />
                                <span v-text="':'" />

                                <span
                                    class="ml-1 whitespace-pre-line"
                                    v-text="parseField(field as {})"
                                />
                            </div>
                        </div>
                    </div>
                </code>
            </div>
        </div>
    </div>
</template>
