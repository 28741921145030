<script setup lang="ts">
import type { ActivityLog, ActivityLogType } from '@/types/activity-log'
import type { PaginatedResponse, PaginationData, uuid } from '@/types/general'

import axios, { AxiosRequestConfig } from 'axios'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import ActivityLogItem from '@/components/settings/activity-log/ActivityLogItem.vue'
import ActivityLogFilter from '@/components/settings/activity-log/ActivityLogFilter.vue'
import PaginationFooter from '@/components/PaginationFooter.vue'

interface Filters {
    logTypes: ActivityLogType[]
    userId: uuid | null
    date: Date[]
}

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()

const activityLogs = ref<ActivityLog[]>([])
const loading = ref(false)
const paginationData = ref<PaginationData>({
    from: 1,
    to: 1,
    perPage: 5,
    lastPage: 1,
    currentPage: Number(route.query.page ?? 1),
    total: 0,
})
const activeFilters = ref<Filters>({
    logTypes: [],
    userId: null,
    date: [],
})

async function fetchActivityLogs(page?: number) {
    loading.value = true

    const params: AxiosRequestConfig['params'] = {
        filter: {
            'user-id': activeFilters.value.userId,
            'log-types': activeFilters.value.logTypes,
            date: activeFilters.value.date,
        },
    }
    const response = await axios.get<PaginatedResponse<ActivityLog>>(
        window.route('company.activity-logs.index', {
            company: authStore.companyId,
            page: page,
            'per-page': 5,
        }),
        { params: params },
    )

    activityLogs.value = response.data.data

    paginationData.value = {
        from: response.data.meta.from,
        to: response.data.meta.to,
        perPage: response.data.meta.per_page,
        lastPage: response.data.meta.last_page,
        currentPage: response.data.meta.current_page,
        total: response.data.meta.total,
    }

    loading.value = false
}

function setFilters(filters: Filters) {
    activeFilters.value = filters
    fetchActivityLogs(1)
}

onMounted(() => {
    fetchActivityLogs()
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('activityLog')" />
    </CrumbsAndActions>

    <div class="w-full flex space-x-12">
        <div class="w-1/4">
            <MyPanel shadow>
                <ActivityLogFilter @fetch="setFilters" />
            </MyPanel>
        </div>
        <div class="w-3/5">
            <MyPanel v-if="activityLogs.length > 0" shadow>
                <ActivityLogItem v-for="log in activityLogs" :key="log.id" :log="log" />
                <PaginationFooter
                    hide-per-page
                    :pagination-data="paginationData"
                    :refetch="(params) => fetchActivityLogs(params?.page ?? 1)"
                    :row-count="activityLogs.length ?? 0"
                />
            </MyPanel>
            <div v-else class="flex justify-center items-center h-96">
                <span class="dark:text-primary-300">{{ t('noActivityLogsFound') }}</span>
            </div>
        </div>
    </div>
</template>
