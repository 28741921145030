import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import { InputVariable } from '@/types/inputs'
import { MessageTemplateType } from '@/types/message-templates'

interface MessageTemplateOptions {
    title: string
    emailSubjectShown: boolean
    emailBodyShown: boolean
    smsBodyShown: boolean
    variables: InputVariable[]
}

export function useMessageTemplateOptions() {
    const { t } = useI18n()

    return computed<Record<MessageTemplateType, MessageTemplateOptions>>(() => {
        return {
            [MessageTemplateType.PackagingStatement]: {
                title: t('packagingStatement'),
                emailSubjectShown: true,
                emailBodyShown: true,
                smsBodyShown: false,
                variables: [
                    { value: 'customerName', description: t('customerNameVariable') },
                    {
                        value: 'customerContactPerson',
                        description: t('customerContactPersonVariable'),
                    },
                    { value: 'companyName', description: t('companyNameVariable') },
                ],
            },
            [MessageTemplateType.SharePackagingTransaction]: {
                title: t('packagingTransaction'),
                emailSubjectShown: true,
                emailBodyShown: true,
                smsBodyShown: false,
                variables: [
                    { value: 'transactionId', description: t('transactionIdVariable') },
                    { value: 'customerName', description: t('customerNameVariable') },
                    { value: 'companyName', description: t('companyNameVariable') },
                ],
            },
            [MessageTemplateType.OwnerDamageReportEmail]: {
                title: t('ownerReportEmail'),
                emailSubjectShown: true,
                emailBodyShown: true,
                smsBodyShown: false,
                variables: [
                    { value: 'customerName', description: t('customerNameVariable') },
                    { value: 'companyName', description: t('companyNameVariable') },
                    { value: 'registrationNumber', description: t('registrationNumber') },
                ],
            },
            [MessageTemplateType.ShareDamageReport]: {
                title: t('damageReport'),
                emailSubjectShown: true,
                emailBodyShown: true,
                smsBodyShown: false,
                variables: [
                    { value: 'customerName', description: t('customerNameVariable') },
                    { value: 'companyName', description: t('companyNameVariable') },
                    { value: 'registrationNumber', description: t('registrationNumber') },
                ],
            },
            [MessageTemplateType.ShipmentDelayAlert]: {
                title: t('shipmentDelayAlert'),
                emailSubjectShown: true,
                emailBodyShown: true,
                smsBodyShown: true,
                variables: [
                    { value: 'shipmentNumber', description: t('shipmentNumber') },
                    { value: 'companyName', description: t('companyNameVariable') },
                    { value: 'newEstimateTime', description: t('newEstimateTimeVariable') },
                    { value: 'newEstimateDateTime', description: t('newEstimateDateTimeVariable') },
                ],
            },
            [MessageTemplateType.SharePODReceipt]: {
                title: t('proofOfDelivery'),
                emailSubjectShown: true,
                emailBodyShown: true,
                smsBodyShown: false,
                variables: [
                    { value: 'customerName', description: t('customerNameVariable') },
                    { value: 'companyName', description: t('companyNameVariable') },
                    { value: 'deliveryDate', description: t('deliveryDateVariable') },
                    { value: 'deliveryDateTime', description: t('deliveryDateTimeVariable') },
                ],
            },
        }
    })
}
