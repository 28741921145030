<script lang="ts" setup>
import axios from 'axios'
import { computed, ref, watch, onBeforeMount } from 'vue'
import { asyncComputed } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { ResourceResponse, uuid } from '@/types/general'
import { addressString } from '@/utils/string'
import { useAuthStore } from '@/stores/auth-store'
import { printElement, generateQRFromUrl } from '@/utils/print-element'
import { Shipment, ShipmentType } from '@/types/delivery-management'
import { datetime } from '@/utils/dates'
import { shipmentTypes } from '@/utils/delivery-management'
import { image, localAsset } from '@/utils/assets'

import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'

export interface Props {
    id?: string
    open?: boolean
}

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const modalContent = ref<HTMLDivElement>()
const shipment = ref<Shipment | null>(null)
const loading = ref(false)

const totalWeight = computed(() => {
    if (!shipment.value) return 0
    return shipment.value.collis.reduce((acc, colli) => acc + colli.weight, 0)
})

const totalColli = computed(() => {
    if (!shipment.value) return 0
    return shipment.value.collis.reduce((acc, colli) => acc + colli.amount, 0)
})

const shipmentIsPickup = computed(() => shipment.value?.type === ShipmentType.Pickup)

const handledAtLabel = computed(() => {
    if (shipmentIsPickup.value) {
        return t('pickedUpAt')
    }

    return t('deliveredAt')
})

const transitStatusLabel = computed(() => {
    if (!shipment.value) return
    if (!shipment.value.deliveredByUser?.name) {
        if (!shipmentIsPickup.value) {
            return t('noDeliveryAttempt')
        }
        return t('noPickupAttempt')
    }
    return shipment.value.deliveredByUser?.name
})

const shipmentAddressName = computed(() => {
    if (!shipment.value) return
    if (shipment.value.addressName) {
        return shipment.value.addressName
    }
    if (!shipmentIsPickup.value) {
        return shipment.value.receivingLocation.name
    }
    return shipment.value.sendingLocation.name
})

const shipmentAddress = computed(() => {
    if (!shipment.value) return

    if (shipment.value.address) {
        return shipment.value.address
    }

    if (!shipmentIsPickup.value) {
        return addressString(shipment.value.receivingLocation).replace(',', '<br />')
    }
    return addressString(shipment.value.sendingLocation).replace(',', '<br />')
})

const shipmentNote = computed(() => {
    if (!shipment.value) return

    if (shipment.value.deliveryNote) {
        return shipment.value.deliveryNote
    }

    if (!shipmentIsPickup.value) {
        return t('noEntitiesForProperty', {
            entity: t('deliveryNote').toLowerCase(),
            property: t('shipment').toLowerCase(),
        })
    }
    return t('noEntitiesForProperty', {
        entity: t('pickupNote').toLowerCase(),
        property: t('shipment').toLowerCase(),
    })
})

const shipmentQR = asyncComputed(() => {
    if (!shipment.value) return
    return generateQRFromUrl(
        router.resolve({
            name: 'dm.shipments.show',
            params: { shipmentId: shipment.value.id },
        }).fullPath,
    )
})

async function fetchShipment() {
    const shipmentId = (route.params.shipmentId as uuid | undefined) || props.id
    if (!shipmentId) return

    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Shipment>>(
            window.route('dm.company.shipments.show', {
                company: authStore.companyId,
                shipment: shipmentId,
            }),
        )
        shipment.value = response.data.data
    } finally {
        loading.value = false
    }
}

function print() {
    printElement(modalContent.value!)
}

function onClose() {
    if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'dm.shipments', query: route.query })
    }
}

onBeforeMount(() => fetchShipment())

watch(() => props.id, fetchShipment)

watch(() => route.params.shipmentId, fetchShipment)
</script>

<template>
    <MyModal :value="props.open" light :max-width="800" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div class="mb-6 flex justify-between">
            <ContentHeading>
                <h1 class="text-xl font-bold text-primary-400" v-text="t('shipment')" />
                <h4 class="font-semibold text-xs mt-1 text-primary-500 dark:text-primary-300">
                    {{ t('type') }}:
                    {{ shipment ? shipmentTypes[shipment.type] : t('notSpecified') }}
                </h4>
            </ContentHeading>

            <div v-if="authStore.companyId" class="flex space-x-2 print:hidden">
                <MyButton icon plain scheme="light" size="small" @click="print()">
                    <mdi:printer />
                </MyButton>
            </div>
        </div>

        <div ref="modalContent">
            <main v-if="shipment" class="grid grid-cols-4 gap-3 print:h-full print:bg-white">
                <!-- First section -->
                <div class="flex flex-col col-span-3 space-y-3 min-h-56">
                    <div
                        class="flex flex-col bg-primary-100 p-3 dark:bg-dark-500 rounded-tl-md h-full"
                    >
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            v-text="t('sender')"
                        />
                        <span v-text="shipment.sendingCompany.name" />
                        <span
                            v-html="addressString(shipment.sendingLocation).replace(',', '<br />')"
                        />
                    </div>

                    <div class="flex flex-col bg-primary-100 p-3 dark:bg-dark-500 h-full">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            v-text="t('receiver')"
                        />
                        <span v-text="shipment.receivingCompany.name" />
                        <span
                            v-html="
                                addressString(shipment.receivingLocation).replace(',', '<br />')
                            "
                        />
                    </div>
                </div>

                <div
                    class="flex flex-col col-start-4 row-start-1 items-center bg-primary-100 p-3 dark:bg-dark-500 rounded-tr-md h-full"
                >
                    <h1
                        class="text-5xl font-bold uppercase text-primary-500 dark:text-primary-400"
                        v-text="t('cmr')"
                    />
                    <h3
                        class="text-lg font-semibold uppercase text-primary-400 dark:text-primary-300"
                        v-text="t('shipment')"
                    />
                    <img
                        class="w-full aspect-square self-center bg-primary-400"
                        :src="shipmentQR"
                    />
                </div>

                <!-- Second section -->
                <div class="flex flex-col col-span-2 space-y-3 h-full">
                    <div class="flex flex-col bg-primary-100 p-3 dark:bg-dark-500 h-full">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            v-text="shipmentIsPickup ? t('placeOfPickup') : t('placeOfDelivery')"
                        />
                        <span v-text="shipmentAddressName" />
                        <span v-html="shipmentAddress" />
                    </div>
                    <div class="flex flex-col bg-primary-100 p-3 dark:bg-dark-500 h-full">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            v-text="t('shipper')"
                        />
                        <span
                            v-text="
                                shipment.shipperCompany?.name ??
                                t('noEntitiesForProperty', {
                                    entity: t('shipper').toLowerCase(),
                                    property: t('shipment').toLowerCase(),
                                })
                            "
                        />
                        <span
                            v-if="shipment.shipperCompany"
                            v-html="addressString(shipment.shipperCompany).replace(',', '<br />')"
                        />
                    </div>
                </div>

                <div class="flex flex-col col-span-2 bg-primary-100 p-3 dark:bg-dark-500 h-full">
                    <h1
                        class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                        v-text="shipment.handledAt ? handledAtLabel : t('plannedAt')"
                    />
                    <span
                        v-text="
                            shipment.handledAt
                                ? datetime(shipment.handledAt)
                                : datetime(shipment.plannedAt)
                        "
                    />

                    <div class="mt-3">
                        <h1
                            class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            v-text="shipmentIsPickup ? t('pickedUpBy') : t('deliveredBy')"
                        />
                        <span v-text="transitStatusLabel" />
                    </div>
                    <div class="mt-3">
                        <h1
                            class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            v-text="shipmentIsPickup ? t('pickupNote') : t('deliveryNote')"
                        />
                        <span v-text="shipmentNote" />
                    </div>
                </div>

                <!-- Third section -->
                <div
                    class="col-span-full px-3 py-2 bg-primary-100 dark:bg-dark-500 h-full flex flex-col"
                >
                    <span
                        class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                        v-text="t('collis')"
                    />
                    <main
                        v-if="shipment.collis.length > 0"
                        class="grid grid-cols-6 font-semibold text-primary-500 dark:text-white dark:bg-dark-500 bg-primary-100 rounded-t-sm justify-items-center px-2.5"
                    >
                        <span class="py-1 text-center" v-text="t('name')" />

                        <span class="py-1" v-text="t('description')" />

                        <span class="py-1" v-text="t('lxwxh')" />

                        <span class="py-1" v-text="t('amount')" />

                        <span class="py-1" v-text="t('weight')" />

                        <span class="py-1" v-text="t('volume')" />
                    </main>

                    <span
                        v-else
                        v-text="
                            t('noEntitiesForProperty', {
                                entity: t('colli').toLowerCase(),
                                property: t('shipment').toLowerCase(),
                            })
                        "
                    />

                    <div
                        v-for="(colli, index) in shipment.collis"
                        :key="colli.id"
                        class="grid grid-cols-6 justify-center break-inside-avoid text-center odd:bg-primary-50 even:bg-primary-100 hover:bg-primary-200 dark:odd:bg-dark-400 dark:even:bg-dark-500 py-2.5 px-1.5 dark:hover:bg-dark-300"
                        :class="{
                            'rounded-b-sm': index === shipment.collis.length - 1,
                        }"
                    >
                        <span class="truncate text-sm" v-text="colli.name" />

                        <span
                            class="max-w-[200px] overflow-ellipsis whitespace-pre-line text-sm"
                            v-text="colli.description"
                        />

                        <span class="text-sm truncate">
                            {{ colli.length }}x{{ colli.width }}x{{ colli.height }} {{ t('cm') }}
                        </span>

                        <span class="text-sm truncate" v-text="colli.amount" />
                        <span class="text-sm truncate"> {{ colli.weight }} {{ t('kg') }} </span>
                        <span class="text-sm truncate"> {{ colli.volume }} {{ t('cbm') }} </span>
                    </div>
                </div>

                <!-- Fourth section -->
                <div class="flex col-span-full h-full break-inside-avoid space-x-3">
                    <div
                        class="flex flex-col w-1/2 rounded-bl-md overflow-hidden dark:bg-dark-500 bg-primary-100 h-full p-3"
                    >
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            v-text="t('receiverSignature')"
                        />
                        <span v-text="shipment.receiverName" />
                        <img
                            v-if="shipment.receiverSignature"
                            class="mt-2 w-16 rounded-full"
                            :src="image(shipment.receiverSignature)"
                        />
                        <span v-else v-text="t('noReceiverSignature')" />
                    </div>

                    <div
                        class="flex col-span-2 w-1/2 bg-primary-100 p-3 dark:bg-dark-500 space-x-10 h-full rounded-br-md"
                    >
                        <div class="flex space-x-1 place-items-center">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('totalKg') + '.'"
                            />
                            <span class="text-lg font-bold"> {{ totalWeight }} {{ t('kg') }} </span>
                        </div>

                        <div class="flex space-x-1 place-items-center">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('totalColli') + '.'"
                            />
                            <span class="text-lg font-bold" v-text="totalColli" />
                        </div>
                    </div>

                    <img
                        :src="localAsset('assets/begreenprintlabel.png')"
                        alt="begreen"
                        class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
                    />
                </div>
            </main>
        </div>

        <h1
            v-if="!shipment && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('shipmentNotFound')"
        />
    </MyModal>
</template>
