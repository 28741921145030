<script lang="ts" setup>
import axios from 'axios'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { Marker, ResourceResponse, uuid } from '@/types/general'
import { localeTime, datetime } from '@/utils/dates'
import { markerColors } from '@/utils/maps'
import { useAuthStore } from '@/stores/auth-store'
import {
    Shipment,
    ShipmentType,
    DeviationState,
    ShipmentEventType,
    ShipmentEvent,
} from '@/types/delivery-management'
import { localAsset, image as imageHelper, image } from '@/utils/assets'
import { addressString } from '@/utils/string'
import { numberFormatter } from '@/utils/numbers'
import { shipmentTypes, shipmentEventTypes } from '@/utils/delivery-management'
import { imageViewerOpenKey } from '@/types/global-injection-keys'
import { LocationPoint } from '@/types/company'

import ColliCard from '@/components/delivery-management/ColliCard.vue'
import MapComponent from '@/components/MapComponent.vue'
import CreateTicketModal from '@/components/delivery-management/CreateTicketModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import HandleShipmentModal from '@/components/delivery-management/HandleShipmentModal.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import DeliveryDescriptionModal from '@/components/delivery-management/DeliveryDescriptionModal.vue'
import ShipmentStatus from '@/components/delivery-management/ShipmentStatus.vue'

export interface Props {
    id?: string
    open?: boolean
}

const props = withDefaults(defineProps<Props>(), { open: true })
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const { t } = useI18n()

const imageViewerOpen = inject(imageViewerOpenKey)!

const modalContent = ref<HTMLDivElement>()
const shipment = ref<Shipment | null>(null)
const loading = ref(false)
const handleShipmentModalOpen = ref(false)
const deliveryDescriptionModalOpen = ref(false)
const ticketModalOpen = ref(false)

const isSenderOrShipper = computed(() => {
    if (!shipment.value) return false
    if (shipment.value.sendingCompany.id === authStore.companyId) return true
    if (shipment.value.shipperCompany?.id === authStore.companyId) return true

    return false
})

const shipmentIsPickup = computed(() => shipment.value?.type === ShipmentType.Pickup)

const destinationLocation = computed(() => {
    if (!shipment.value) return null
    return shipmentIsPickup.value
        ? shipment.value.sendingLocation
        : shipment.value.receivingLocation
})

const destinationCompany = computed(() => {
    if (!shipment.value) return null
    return shipmentIsPickup.value ? shipment.value.sendingCompany : shipment.value.receivingCompany
})

const totalWeight = computed(() => {
    if (!shipment.value) return 0
    const totalWeight = shipment.value.collis.reduce((acc, colli) => acc + colli.weight, 0)

    return numberFormatter.format(totalWeight)
})

const totalVolume = computed(() => {
    if (!shipment.value) return 0
    const totalVolume = shipment.value.collis.reduce((acc, colli) => acc + colli.volume, 0)

    return numberFormatter.format(totalVolume)
})

const totalColli = computed(() => {
    if (!shipment.value) return 0
    return shipment.value.collis.reduce((acc, colli) => acc + colli.amount, 0)
})

const shipmentEvents = computed(() => {
    if (shipment.value === null) return []

    // Temporary fix to make sure delivery / pickup info is still shown when the shipment has no events.
    // This should be removed once we've migrated delivery / pickup info to shipment event in the database.
    if (shipment.value.events.length === 0 && shipment.value.handledAt) {
        const deliveryEvent: ShipmentEvent = {
            id: shipment.value.id,
            user: shipment.value.deliveredByUser ?? { id: '', name: '' },
            type:
                shipment.value.type === ShipmentType.Delivery
                    ? ShipmentEventType.Delivery
                    : ShipmentEventType.Pickup,
            signatureName: shipment.value.receiverName,
            signaturePath: shipment.value.receiverSignature,
            location: shipment.value.tourEvent?.deliveryLocation ?? { latitude: 0, longitude: 0 },
            note: shipment.value.deliveryNote,
            webHandled: false,
            transpiredAt: shipment.value.handledAt,
        }

        return [deliveryEvent]
    }

    return shipment.value.events
})

const markers = computed<Marker[]>(() => {
    const markerList: Marker[] = []

    if (!shipment.value) return markerList

    const receivingLocation =
        shipment.value.addressLocation ?? shipment.value.receivingLocation.location

    if (!shipmentIsPickup.value && isValidLocation(receivingLocation)) {
        markerList.push({
            location: receivingLocation,
            content: t('receivingLocation'),
        })
    }

    const sendingLocation = shipment.value.sendingLocation.location
    if (shipmentIsPickup.value && isValidLocation(sendingLocation)) {
        markerList.push({
            location: sendingLocation,
            content: t('sendingLocation'),
        })
    }

    shipmentEvents.value.forEach((event) => {
        if (isValidLocation(event.location)) {
            const isLoadUnload = [
                ShipmentEventType.TerminalLoad,
                ShipmentEventType.TerminalUnload,
            ].includes(event.type)
            markerList.push({
                location: event.location,
                content: t(shipmentEventTypes[event.type]),
                fillColor: isLoadUnload ? markerColors.blue : markerColors.green,
            })
        }
    })
    return markerList
})

async function fetchShipment(id?: uuid) {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Shipment>>(
            window.route('dm.company.shipments.show', {
                company: authStore.companyId,
                shipment: id ?? props.id!,
            }),
        )
        shipment.value = response.data.data
    } finally {
        loading.value = false
    }
}

function eventIsDeliveryOrPickup(type: ShipmentEventType): boolean {
    return [ShipmentEventType.Delivery, ShipmentEventType.Pickup].includes(type)
}

function isValidLocation(location: LocationPoint | null): location is LocationPoint {
    if (!location) return false
    return location.latitude !== 0 && location.longitude !== 0
}

function onClose() {
    if (imageViewerOpen.value) return

    if (props.id) {
        emit('update:open', false)
    } else if (route.name?.toString().includes('customer')) {
        router.replace({
            name: 'dm.customers.show',
            params: { ...route.params },
            query: route.query,
        })
    } else {
        router.replace({ name: 'dm.shipments', query: route.query })
    }
}

function handleShipment() {
    handleShipmentModalOpen.value = true
}

async function handleShipmentModalClosed() {
    handleShipmentModalOpen.value = false
}

function openDeliveryDescriptionModal() {
    deliveryDescriptionModalOpen.value = true
}

onBeforeMount(async () => {
    if (route.params.shipmentId) fetchShipment(route.params.shipmentId as uuid)
    if (props.id) await fetchShipment()
})

watch(
    () => props.id,
    () => {
        if (props.id) {
            fetchShipment()
        }
    },
)
watch(
    () => route.params.shipmentId,
    () => {
        if (route.params.shipmentId) fetchShipment(route.params.shipmentId as uuid)
    },
)
</script>

<template>
    <MyModal :value="props.open" :max-width="1400" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div v-if="shipment" ref="modalContent" class="space-y-3">
            <div class="flex justify-between space-x-6">
                <div class="flex w-1/2 flex-col space-y-5">
                    <div class="flex">
                        <ContentHeading>
                            <div class="flex space-x-1">
                                <h1 class="text-lg font-semibold text-primary-400">
                                    {{ t('shipment') }}
                                </h1>
                                <div
                                    v-if="shipment.unsuccessful"
                                    class="flex space-x-1 items-center"
                                >
                                    <span class="font-semibold text-primary-400">|</span>
                                    <h1 class="text-lg font-semibold text-red-400">
                                        {{ t('unsuccessful') }}
                                    </h1>
                                </div>
                            </div>

                            <div class="flex space-x-2 text-xs">
                                <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                                    {{ t('type') }}:
                                </h4>
                                <h4 class="font-medium dark:text-primary-400">
                                    {{ t(shipmentTypes[shipment.type]) }}
                                </h4>
                            </div>
                        </ContentHeading>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('shipmentNumber')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="shipment.shipmentNumber ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/2">
                            <div v-if="shipment.soldTo" class="flex flex-col w-1/2">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('soldTo')"
                                />
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="shipment.soldTo"
                                />
                            </div>
                            <div v-if="shipment.shipTo" class="flex flex-col w-1/2">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('shipTo')"
                                />
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="shipment.shipTo"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('name')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="shipment.name ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/2">
                            <div v-if="shipment.handledAt" class="flex flex-col w-full">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('handledAt')"
                                />
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="datetime(shipment.handledAt)"
                                />
                            </div>
                            <div
                                v-else-if="shipment.plannedAt && shipment.handledAt === null"
                                class="flex flex-col w-full"
                            >
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('plannedAt')"
                                />
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="datetime(shipment.plannedAt)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('category')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="shipment.category?.name ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/2">
                            <div
                                v-if="shipment.handleableBefore || shipment.handleableAfter"
                                class="flex w-1/2 flex-col"
                            >
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('timeslot')"
                                />
                                <div>
                                    <span
                                        v-if="!shipment.handleableAfter"
                                        class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                        v-text="t('before') + ' '"
                                    />
                                    <span
                                        v-if="!shipment.handleableBefore"
                                        class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                        v-text="t('after') + ' '"
                                    />
                                    <span
                                        v-if="shipment.handleableAfter"
                                        class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                        v-text="localeTime(shipment.handleableAfter)"
                                    />
                                    <span
                                        v-if="shipment.handleableAfter && shipment.handleableBefore"
                                        class="font-semibold mx-1"
                                        >-</span
                                    >

                                    <span
                                        v-if="shipment.handleableBefore"
                                        class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                        v-text="localeTime(shipment.handleableBefore)"
                                    />
                                </div>
                            </div>
                            <div class="flex w-1/2 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('status')"
                                />
                                <ShipmentStatus :shipment="shipment" />
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full flex-col">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('references')"
                        />
                        <span
                            class="text-md font-semibold text-primary-500 dark:text-primary-100"
                            v-text="shipment.references ?? t('notSpecified')"
                        />
                    </div>

                    <div v-if="shipment.podReceiptId" class="flex">
                        <div class="flex flex-col justify-start w-1/2 text-sm print:hidden">
                            <h3
                                class="font-semibold uppercase text-primary-400"
                                v-text="t('receipt')"
                            />
                            <RouterLink
                                :to="{
                                    name: 'pod.receipts.show',
                                    params: { receiptId: shipment.podReceiptId },
                                }"
                                class="text-sm w-fit font-semibold uppercase text-primary-500 hover:text-primary-400 dark:text-primary-100"
                                v-text="t('view')"
                            />
                        </div>
                    </div>

                    <div class="flex">
                        <div class="flex flex-col justify-start w-1/2 text-sm print:hidden">
                            <h3 class="font-semibold uppercase text-primary-400">
                                {{ shipment.tour ? t('tour') : t('notAssignedToTour') }}
                            </h3>
                            <RouterLink
                                v-if="shipment.tour"
                                :to="{
                                    name: 'dm.tours.show',
                                    params: { tourId: shipment.tour?.id },
                                    query: route.query,
                                }"
                                class="text-sm w-fit font-semibold uppercase text-primary-500 hover:text-primary-400 dark:text-primary-100"
                                v-text="t('view') + ' ' + shipment.tour?.name"
                            />
                        </div>
                    </div>

                    <div
                        v-if="shipment.tourEvent && shipment.tourEvent.transactions.length > 0"
                        class="flex flex-col !mt-3 justify-between w-1/2 text-sm print:hidden"
                    >
                        <h3 class="font-semibold uppercase text-primary-400">
                            {{ t('transactions') }}
                        </h3>
                        <div
                            v-for="transaction in shipment.tourEvent.transactions"
                            :key="transaction.id"
                        >
                            <RouterLink
                                :to="{
                                    name: 'packaging.transactions.show',
                                    params: { transactionId: transaction.id },
                                    query: route.query,
                                }"
                                class="text-sm font-semibold uppercase text-primary-500 hover:text-primary-400 dark:text-primary-100"
                                v-text="datetime(transaction.confirmedAt)"
                            />
                        </div>
                    </div>
                    <div v-if="shipment.tickets.length > 0" class="flex flex-col col-span-3">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('tickets')"
                        />
                        <div v-for="ticket in shipment.tickets" :key="ticket.id">
                            <div class="flex items-center space-x-1">
                                <span
                                    v-if="ticket.tags"
                                    class="text-sm font-semibold text-primary-500 cursor-pointer hover:text-primary-400 dark:text-primary-100"
                                    @click="
                                        router.push({
                                            name: 'dm.tickets.show',
                                            params: { ticketId: ticket.id },
                                        })
                                    "
                                >
                                    {{ ticket.tags.map((tag) => t(tag.name)).join(', ') }}
                                </span>
                                <span
                                    v-if="ticket.state === DeviationState.Closed"
                                    class="rounded-full h-3 w-3 bg-gray-400 flex-shrink-0"
                                />
                                <span
                                    v-if="ticket.state === DeviationState.Open"
                                    class="rounded-full h-3 w-3 bg-yellow-500 flex-shrink-0"
                                />

                                <span
                                    v-if="ticket.state === DeviationState.Solved"
                                    class="rounded-full h-3 w-3 bg-green-500 flex-shrink-0"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="
                            shipment.deviations.length > 0 &&
                            authStore.companyId === shipment.shipperCompany?.id
                        "
                        class="flex flex-col col-span-3"
                    >
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('deviations')"
                        />
                        <div v-for="deviation in shipment.deviations" :key="deviation.id">
                            <div class="flex items-center space-x-1">
                                <span
                                    v-if="deviation.tags"
                                    class="text-sm font-semibold text-primary-500 cursor-pointer hover:text-primary-400 dark:text-primary-100"
                                    @click="
                                        router.push({
                                            name: 'dm.deviations.show',
                                            params: { deviationId: deviation.id },
                                        })
                                    "
                                >
                                    {{ deviation.tags.map((tag) => t(tag.name)).join(', ') }}
                                </span>
                                <span
                                    v-if="deviation.state === DeviationState.Closed"
                                    class="rounded-full h-3 w-3 bg-gray-400 flex-shrink-0"
                                />
                                <span
                                    v-if="deviation.state === DeviationState.Open"
                                    class="rounded-full h-3 w-3 bg-yellow-500 flex-shrink-0"
                                />

                                <span
                                    v-if="deviation.state === DeviationState.Solved"
                                    class="rounded-full h-3 w-3 bg-green-500 flex-shrink-0"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="shipmentEvents.length > 0">
                        <h3
                            class="text-lg font-semibold uppercase text-primary-400 mb-2"
                            v-text="t('history')"
                        />

                        <div
                            v-for="event in shipmentEvents"
                            :key="event.id"
                            class="flex w-full rounded-xl bg-white shadow-md dark:bg-dark-400 p-4 mb-4 last:mb-0"
                        >
                            <div class="flex flex-col text-xs">
                                <div class="flex items-center space-x-1">
                                    <div
                                        class="text-sm"
                                        v-text="t(shipmentEventTypes[event.type])"
                                    />
                                    <template
                                        v-if="
                                            shipment.unsuccessful &&
                                            eventIsDeliveryOrPickup(event.type)
                                        "
                                    >
                                        <span v-text="'|'" />
                                        <div
                                            class="text-sm text-red-400"
                                            v-text="t('unsuccessful')"
                                        />
                                    </template>
                                </div>

                                <div
                                    class="text-xs mb-2"
                                    v-text="t('byUser', { user: event.user.name })"
                                />

                                <div v-if="event.note" class="max-w-[200px]">
                                    <span class="font-semibold mr-2" v-text="t('note')" />
                                    <span v-text="event.note" />
                                </div>

                                <div
                                    v-if="event.signatureName || event.signaturePath"
                                    class="mt-1 max-w-[200px]"
                                >
                                    <span
                                        class="font-semibold mr-2"
                                        v-text="t('receiverSignature')"
                                    />
                                    <span v-text="event.signatureName" />
                                    <img
                                        v-if="event.signaturePath"
                                        class="mt-2 w-16 rounded-full"
                                        :alt="event.signatureName || ''"
                                        :src="image(event.signaturePath)"
                                    />
                                </div>
                            </div>

                            <div class="flex space-x-1.5 items-start ml-auto text-sm">
                                <span v-text="datetime(event.transpiredAt)" />
                                <div
                                    v-if="event.webHandled"
                                    v-tooltip="
                                        t('handledFromWebExplanation', {
                                            user: event.user?.name,
                                            time: datetime(event.createdAt!),
                                        })
                                    "
                                    class="flex ml-auto text-sm"
                                >
                                    <mdi:desktop-mac />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="shipment.collis.length > 0">
                        <div class="mb-4">
                            <h3
                                class="text-lg font-semibold uppercase text-primary-400"
                                v-text="t('collis')"
                            />

                            <div class="mt-1 flex space-x-1.5 text-xs">
                                <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                                    {{ t('totalKg') }}:
                                </h4>
                                <h4 class="font-medium dark:text-primary-400">
                                    {{ totalWeight }} kg
                                </h4>
                            </div>

                            <div class="mt-1 flex space-x-1.5 text-xs">
                                <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                                    {{ t('totalVolume') }}:
                                </h4>
                                <h4 class="font-medium dark:text-primary-400">
                                    {{ totalVolume }} cbm
                                </h4>
                            </div>

                            <div class="mt-1 flex space-x-1.5 text-xs">
                                <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                                    {{ t('totalColli') }}:
                                </h4>
                                <h4 class="font-medium dark:text-primary-400">{{ totalColli }}</h4>
                            </div>
                        </div>

                        <div class="space-y-4">
                            <ColliCard
                                v-for="colli in shipment.collis"
                                :key="colli.id"
                                :colli="colli"
                                show-shipment-information
                            />
                        </div>
                    </div>

                    <div v-else>
                        <span
                            class="text-lg font-semibold uppercase text-primary-400"
                            v-text="t('noCollisForShipment')"
                        />
                    </div>
                </div>

                <div class="space-y-4 w-1/2">
                    <div class="flex justify-between space-x-4">
                        <div class="flex w-full flex-col">
                            <div class="flex justify-end space-x-3">
                                <MyButton
                                    v-if="!shipment.handledAt"
                                    scheme="primary"
                                    size="small"
                                    class="self-end"
                                    @click="handleShipment"
                                    v-text="
                                        shipment.type === ShipmentType.Delivery
                                            ? t('markShipmentAsDelivered')
                                            : t('markShipmentAsPickedup')
                                    "
                                />
                                <MyButton
                                    v-if="shipment.shipperCompany && isSenderOrShipper"
                                    scheme="primary"
                                    size="small"
                                    class="self-end"
                                    @click="ticketModalOpen = true"
                                    v-text="t('createEntity', { entity: t('ticket') })"
                                />
                            </div>

                            <div class="flex mt-2 items-center">
                                <p
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="!shipmentIsPickup ? t('receiver') : t('sender')"
                                />
                            </div>

                            <div class="flex flex-col mt-1 w-full">
                                <div
                                    class="flex justify-between h-16 text-primary-500 dark:text-primary-300 rounded-t-xl bg-primary-50 px-4 py-3 dark:bg-dark-500 print:px-0"
                                >
                                    <div class="flex flex-col">
                                        <div class="flex items-center">
                                            <RouterLink
                                                v-if="
                                                    shipment.receivingCustomerId &&
                                                    !shipmentIsPickup
                                                "
                                                :to="{
                                                    name: 'dm.customers.show',
                                                    params: { id: shipment.receivingCustomerId },
                                                }"
                                            >
                                                <div class="flex">
                                                    <span
                                                        class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                                        v-text="shipment.receivingCompany.name"
                                                    />
                                                    <div
                                                        v-if="
                                                            shipment.receivingLocation.name !==
                                                            shipment.receivingCompany.name
                                                        "
                                                        class="flex items-center"
                                                    >
                                                        <span class="mx-1">-</span>
                                                        <span
                                                            class="text-sm"
                                                            v-text="shipment.receivingLocation.name"
                                                        />
                                                    </div>
                                                </div>
                                            </RouterLink>
                                            <div v-else class="flex">
                                                <span
                                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                                    v-text="destinationCompany?.name"
                                                />
                                                <div
                                                    v-if="
                                                        destinationLocation?.name !==
                                                        destinationCompany?.name
                                                    "
                                                    class="flex items-center"
                                                >
                                                    <span
                                                        class="mx-1 dark:text-primary-100"
                                                        v-text="'-'"
                                                    />

                                                    <span
                                                        class="text-sm dark:text-primary-100"
                                                        v-text="destinationLocation?.name"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <span
                                            v-if="destinationLocation"
                                            class="text-xs"
                                            v-text="
                                                shipment.address ??
                                                addressString(destinationLocation)
                                            "
                                        />
                                    </div>
                                    <MyButton
                                        v-if="shipment.deliveryDescriptionId"
                                        v-tooltip="t('deliveryDescription')"
                                        icon
                                        plain
                                        scheme="light"
                                        size="medium"
                                        class="cursor-pointer dark:text-primary-200"
                                        @click="openDeliveryDescriptionModal"
                                    >
                                        <mdi:clipboard-text-search-outline />
                                    </MyButton>
                                </div>

                                <MapComponent
                                    v-if="shipment"
                                    class="h-[240px] rounded-b-xl shadow-xl print:shadow-none overflow-hidden"
                                    :markers="markers"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between space-x-4">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="!shipmentIsPickup ? t('sender') : t('receiver')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="
                                    !shipmentIsPickup
                                        ? shipment.sendingCompany.name
                                        : shipment.receivingCompany.name
                                "
                            />

                            <img
                                v-if="shipment?.sendingCompany.logo && !shipmentIsPickup"
                                class="mt-2 w-24 rounded-xl bg-primary-200"
                                :src="imageHelper(shipment?.sendingCompany.logo, 'thumbnail')"
                            />

                            <img
                                v-else-if="shipment?.receivingCompany.logo && shipmentIsPickup"
                                class="mt-2 w-24 rounded-xl bg-primary-200"
                                :src="imageHelper(shipment?.receivingCompany.logo, 'thumbnail')"
                            />
                        </div>
                    </div>
                    <div class="flex w-full">
                        <div v-if="shipment.shipperCompany" class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('shipper')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="shipment?.shipperCompany.name"
                            />

                            <img
                                v-if="shipment?.shipperCompany.logo"
                                class="mt-2 w-24 rounded-xl bg-primary-200"
                                :src="imageHelper(shipment?.shipperCompany.logo, 'thumbnail')"
                            />
                        </div>
                        <div v-if="shipment.haulierCompany" class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('haulier')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="shipment?.haulierCompany.name"
                            />

                            <img
                                v-if="shipment?.haulierCompany.logo"
                                class="mt-2 w-24 rounded-xl bg-primary-200"
                                :src="imageHelper(shipment?.haulierCompany.logo, 'thumbnail')"
                            />
                        </div>
                    </div>
                    <div v-if="shipment.images.length > 0">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('images')"
                        />

                        <MyImageViewer class="grid grid-cols-4 gap-3 mt-2">
                            <MyFileAttachment
                                v-for="file in shipment.images"
                                :key="file.id"
                                class="cursor-pointer"
                                :file="file"
                            />
                        </MyImageViewer>
                    </div>
                </div>
            </div>

            <img
                :src="localAsset('assets/begreenprintlabel.png')"
                alt="begreen"
                class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
            />
        </div>

        <h1
            v-if="!shipment && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('shipmentNotFound')"
        />
    </MyModal>
    <HandleShipmentModal
        v-if="shipment"
        v-model="handleShipmentModalOpen"
        :shipment-id="shipment.id"
        :shipment-type="shipment.type"
        @close="handleShipmentModalClosed"
        @saved="fetchShipment(shipment.id)"
    />

    <DeliveryDescriptionModal
        v-if="shipment && shipment.deliveryDescriptionId"
        v-model="deliveryDescriptionModalOpen"
        :delivery-description-id="shipment.deliveryDescriptionId"
    />

    <CreateTicketModal v-model="ticketModalOpen" :shipment="shipment!" />
</template>
