<script lang="ts">
interface Props {
    modelValue: boolean
    shipment?: TicketShipment
    tour?: TicketTour
}
</script>

<script setup lang="ts">
import type { FileAttachment, MinimalResource, ResourceResponse, uuid } from '@/types/general'

import axios from 'axios'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownStringOption } from '@/types/inputs'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { useFileUploader } from '@/hooks/use-file-uploader'
import router from '@/router'
import { MinimalCompanyTag, Shipment, Tour } from '@/types/delivery-management'

import TourSelection from '@/components/delivery-management/TourSelection.vue'
import ShipmentSelection from '@/components/delivery-management/manage-tour/ShipmentSelection.vue'
import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

export type TicketTour = Pick<Tour, 'id' | 'name' | 'company' | 'shipperCompany'>

export type TicketShipment = Pick<
    Shipment,
    'id' | 'shipmentNumber' | 'name' | 'shipperCompany' | 'sendingCompany' | 'tour'
>

interface Form {
    companyId: uuid
    userId: uuid | null
    clientCompanyId: uuid | null
    tourId: uuid | null
    shipmentId: uuid | null
    tags: uuid[]
    files: FileAttachment[]
    note: string | null
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, loading, reset } = useForm<Form>({
    companyId: authStore.companyId,
    userId: authStore.user!.id,
    clientCompanyId: null,
    tourId: null,
    shipmentId: null,
    tags: [],
    files: [],
    note: '',
})

const agents = useLazyLoadedList<DropdownStringOption>(fetchAgents)
const tags = useLazyLoadedList<DropdownStringOption>(fetchTags)

const selectedShipment = ref<TicketShipment | null>(null)
const shipmentSelectionModalOpen = ref(false)
const selectedTour = ref<TicketTour | null>(null)
const tourSelectionModalOpen = ref(false)

const { filesUploading, uploadFiles } = useFileUploader({
    addFile(file) {
        data.files.push(file)
    },
    updateFile(fileId, path) {
        data.files = data.files.map((file) => {
            if (file.id === fileId) return { ...file, path }

            return file
        })
    },
})

async function fetchAgents(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId, web: true }),
    )

    return response.data.map((agent) => ({
        value: agent.id,
        label: agent.name,
    }))
}

async function fetchTags(): Promise<DropdownStringOption[]> {
    const response = await axios.get<ResourceResponse<MinimalCompanyTag[]>>(
        window.route('dm.company.tags.index', { company: data.companyId }),
    )

    return response.data.data.map((tag) => ({ value: tag.id, label: t(tag.name) }))
}

function removeFile(file: FileAttachment) {
    data.files = data.files.filter((f) => f !== file)
}

async function onSubmit() {
    const response = await submit<{ id: uuid }>(
        'POST',
        window.route('dm.company.tickets.store', {
            company: authStore.companyId,
        }),
    )
    if (response !== undefined) {
        emit('update:modelValue', false)
        router.push({ name: 'dm.tickets.show', params: { ticketId: response.id } })
    }
}

function selectTour(tour: TicketTour) {
    selectedTour.value = tour
    data.tourId = tour.id
    data.companyId = tour.company.id
    data.clientCompanyId = tour.shipperCompany?.id ?? null
    data.shipmentId = null
    tourSelectionModalOpen.value = false
}

function selectShipment(shipment: TicketShipment) {
    if (shipment.tour) {
        selectTour({
            ...shipment.tour,
            shipperCompany: shipment.shipperCompany,
            company: shipment.sendingCompany,
        })
    }
    selectedShipment.value = shipment
    data.shipmentId = shipment.id
    data.companyId = shipment.sendingCompany.id
    data.clientCompanyId =
        shipment?.shipperCompany?.id ?? selectedTour.value?.shipperCompany?.id ?? null
    shipmentSelectionModalOpen.value = false
}

function removeTour() {
    if (!selectedShipment.value?.shipperCompany && !selectedTour.value?.shipperCompany) {
        data.clientCompanyId = null
    }

    selectedTour.value = null
    data.tourId = null
    if (data.shipmentId === null) {
        data.companyId = authStore.companyId
        data.clientCompanyId = null
    }
}

function removeShipment() {
    if (!selectedTour.value?.shipperCompany && !selectedShipment.value?.shipperCompany) {
        data.clientCompanyId = null
    }

    selectedShipment.value = null
    data.shipmentId = null

    if (data.tourId === null) {
        data.companyId = authStore.companyId
        data.clientCompanyId = null
    }
}

watch(
    () => data.companyId,
    () => {
        tags.reset()
        data.tags = []
    },
)

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue) {
            agents.fetch()

            if (props.shipment) {
                selectShipment(props.shipment)
            } else if (props.tour) {
                selectTour(props.tour)
            }
        } else {
            tags.reset()
            reset()
        }
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="850" @close="emit('update:modelValue', false)">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ t('createEntity', { entity: t('ticket') }) }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="flex">
                <div class="flex w-full flex-col space-x-3 pr-3">
                    <div class="flex items-end space-x-3">
                        <div class="flex w-1/2 flex-col space-y-3">
                            <div>
                                <MyInputLabel v-text="t('company')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="
                                        selectedTour?.company?.name ??
                                        selectedShipment?.sendingCompany?.name ??
                                        authStore.company?.name
                                    "
                                ></h4>
                            </div>
                            <div>
                                <MyInputLabel v-text="t('shipper')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="
                                        selectedShipment?.shipperCompany?.name ??
                                        selectedTour?.shipperCompany?.name ??
                                        t('noShipper')
                                    "
                                />
                            </div>
                        </div>

                        <div class="flex w-1/2 flex-col space-y-3">
                            <div>
                                <MySelect
                                    v-model="data.tags"
                                    name="tags"
                                    group-class="w-full"
                                    :label="t('tags')"
                                    multiple
                                    :options="tags.items.value"
                                    :loading="tags.fetching.value"
                                    searchable
                                    @focus="tags.fetch"
                                />
                            </div>

                            <div class="flex h-fit">
                                <MySelect
                                    v-model="data.userId"
                                    :options="agents.items.value"
                                    :loading="agents.fetching.value"
                                    group-class="w-full"
                                    :label="t('agent')"
                                    searchable
                                    @focus="agents.fetch()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="flex w-full space-x-3 mt-4 !ml-0 items-end">
                        <div class="flex flex-col w-1/2">
                            <MyInputLabel class="mb-1" v-text="t('tour')" />

                            <div class="flex space-x-2">
                                <div v-if="props.tour || data.tourId" class="w-full">
                                    <h4
                                        class="flex h-10 cursor-default break-all items-center px-2 rounded-xl border border-primary-200 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                        v-text="selectedTour?.name ?? t('noTourName')"
                                    ></h4>
                                </div>
                                <div v-else class="w-full">
                                    <h4
                                        class="flex h-10 cursor-default items-center rounded-xl border border-primary-200 p-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                        v-text="t('noTourSelected')"
                                    ></h4>
                                </div>
                                <MyButton
                                    v-if="!props.tour && !props.shipment"
                                    :disabled="loading"
                                    class="px-2.5"
                                    type="button"
                                    scheme="primary"
                                    icon
                                    plain
                                    @click="tourSelectionModalOpen = true"
                                >
                                    <mdi:magnify class="w-4 h-4" />
                                </MyButton>

                                <MyButton
                                    v-if="!props.tour && data.tourId && !props.shipment"
                                    class="px-2.5"
                                    :disabled="loading || !!data.shipmentId"
                                    type="button"
                                    scheme="danger"
                                    icon
                                    @click="removeTour()"
                                >
                                    <mdi:trash-can class="w-4 h-4" />
                                </MyButton>
                            </div>
                        </div>
                        <div class="flex flex-col h-fit w-1/2">
                            <MyInputLabel class="mb-1" v-text="t('shipment')" />

                            <div class="flex space-x-2">
                                <div v-if="props.shipment || data.shipmentId" class="w-full">
                                    <h4
                                        class="flex h-10 cursor-default px-2 items-center rounded-xl border border-primary-200 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                        v-text="
                                            selectedShipment?.shipmentNumber ??
                                            selectedShipment?.name ??
                                            t('noShipmentNumberOrName')
                                        "
                                    ></h4>
                                </div>
                                <div v-else class="w-full">
                                    <div
                                        class="flex h-10 cursor-default items-center rounded-xl border border-primary-200 p-2 text-sm text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    >
                                        <span
                                            v-if="!selectedTour?.name"
                                            class="font-semibold"
                                            v-text="t('noShipmentSelected')"
                                        />
                                        <span
                                            v-else
                                            class="font-semibold"
                                            v-text="
                                                t('noShipmentSelectedForTour', {
                                                    tour: selectedTour?.name,
                                                })
                                            "
                                        />
                                    </div>
                                </div>

                                <MyButton
                                    v-if="!props.shipment"
                                    :disabled="loading"
                                    type="button"
                                    scheme="primary"
                                    class="px-2.5"
                                    icon
                                    plain
                                    @click="shipmentSelectionModalOpen = true"
                                >
                                    <mdi:magnify class="w-4 h-4" />
                                </MyButton>
                                <MyButton
                                    v-if="!props.shipment && data.shipmentId"
                                    class="px-2.5"
                                    :disabled="loading"
                                    type="button"
                                    scheme="danger"
                                    icon
                                    @click="removeShipment()"
                                >
                                    <mdi:trash-can class="w-4 h-4" />
                                </MyButton>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-start w-1/2 mt-3 space-y-3 !ml-0">
                        <MyTextarea v-model="data.note" name="note" :label="t('comment')" />
                        <MyFileSelect
                            no-border
                            type="image/*,application/pdf"
                            multiple
                            @selected:multiple="uploadFiles"
                        >
                            <mdi:attachment class="dark:text-primary-50" />
                        </MyFileSelect>
                    </div>

                    <div v-if="data.files.length > 0" class="flex flex-col mt-3">
                        <MyInputLabel v-text="t('files')" />

                        <div class="mt-2 grid grid-cols-4 gap-3">
                            <MyFileAttachment
                                v-for="file in data.files"
                                :key="file.id"
                                :file="file"
                                removable
                                :uploading="filesUploading.includes(file.id)"
                                @on-remove-clicked="removeFile(file)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4 flex justify-end gap-6">
                <MyButton :disabled="loading" scheme="primary" v-text="t('create')" />
            </div>
        </MyForm>
    </MyModal>

    <MyModal
        :value="shipmentSelectionModalOpen"
        :max-width="700"
        @close="shipmentSelectionModalOpen = false"
    >
        <ShipmentSelection
            isolated
            :tour-id="selectedTour?.id"
            @shipment-selected="selectShipment($event)"
            @update:open="shipmentSelectionModalOpen = !shipmentSelectionModalOpen"
        />
    </MyModal>

    <MyModal
        :value="tourSelectionModalOpen"
        :max-width="800"
        @close="tourSelectionModalOpen = false"
    >
        <TourSelection
            @tour-selected="selectTour($event)"
            @update:open="tourSelectionModalOpen = !tourSelectionModalOpen"
        />
    </MyModal>
</template>
