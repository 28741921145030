¨
<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'

import { useMittListener } from '@/hooks/use-mitt-listener'
import { useAuthStore } from '@/stores/auth-store'
import { useTransactionStore } from '@/stores/transaction-store'
import { LicenseType } from '@/types/company'
import { PermissionType, uuid } from '@/types/general'
import {
    LinkedTransactionType,
    Transaction,
    TransactionImageType,
    TransactionState,
    TransactionType,
} from '@/types/transaction'
import { asset, image as imageHelper, localAsset } from '@/utils/assets'
import { datetime } from '@/utils/dates'
import { printElement } from '@/utils/print-element'
import { imageViewerOpenKey } from '@/types/global-injection-keys'

import UpdateEconomyModal from '@/components/packaging/transactions/UpdateEconomyModal.vue'
import BasicShareModal from '@/components/BasicShareModal.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyCompanyCard from '@/components/my-components/MyCompanyCard.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import ModalAttachment from '@/components/ModalAttachment.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import MyDispatchLog from '@/components/my-components/MyDispatchLog.vue'
import NilTradeWarning from '@/components/packaging/products/NilTradeWarning.vue'
import MapComponent from '@/components/MapComponent.vue'

export interface Props {
    id?: uuid
    open?: boolean
}
const emit = defineEmits<{ (e: 'update:open', value: boolean): void; (e: 'close'): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const transactionStore = useTransactionStore()

const imageViewerOpen = inject(imageViewerOpenKey)!

const modalContent = ref<HTMLDivElement>()
const shareTransactionOpen = ref(false)
const updateEconomyModalOpen = ref(false)
const loading = ref(false)
const transaction = ref<Transaction | null>(null)

const isNilTrade = computed(() => {
    return !transaction.value?.products.some(
        (product) => product.delivered > 0 || product.received > 0,
    )
})

const shareEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('packaging.company.transactions.share', {
        company: authStore.companyId,
        transaction: transaction.value?.id ?? '',
    })
})

const largeLayout = computed(() => {
    if (!transaction.value) return false

    return (
        transaction.value.location ||
        transaction.value.details.note ||
        transaction.value.images.length > 0 ||
        transaction.value.consignmentNotes.length > 0 ||
        transaction.value.details.shippingCode ||
        transaction.value.details.palletReceiptNumber ||
        transaction.value.details.trailerNumber ||
        transaction.value.details.truckNumber ||
        transaction.value.podReceiptId ||
        transaction.value.companyDetails.economy ||
        transaction.value.destinationTransaction ||
        transaction.value.transitTransaction ||
        transaction.value.linkedTransactions.length > 0
    )
})

const heading = computed(() => {
    switch (transaction.value?.type) {
        case TransactionType.PalletBank:
            return 'palletBank'
        case TransactionType.Reset:
            return 'resetTransaction'
        case TransactionType.Location:
            return 'locationTransaction'
        case TransactionType.Backend:
            return 'webTransaction'
        case TransactionType.Load:
            return 'loadingTransaction'
        case TransactionType.Unload:
            return 'unloadingTransaction'
        case TransactionType.Transit:
            return 'transitTransaction'
        case TransactionType.MirroredHubTransaction:
            return 'mirroredHubTransaction'
        default:
            if (transaction.value?.receivingUser) return 'activeTransaction'
            else return 'manualTransaction'
    }
})

async function fetchTransaction() {
    const transactionId = (route.params.transactionId as uuid | undefined) || props.id
    if (!transactionId) return

    loading.value = true

    try {
        transaction.value = await transactionStore.findTransaction(transactionId)
    } finally {
        loading.value = false
    }
}

function onClose() {
    if (imageViewerOpen.value) return

    if (props.id) {
        emit('update:open', false)
        emit('close')
    } else if (authStore.user == null) {
        router.push({ name: 'login' })
    } else if (route.name?.toString().includes('customer')) {
        router.replace({
            name: 'packaging.customers.show',
            params: { ...route.params },
            query: route.query,
        })
    } else {
        router.replace({ name: 'packaging.transactions', query: route.query })
    }
}

function transactionLink(id: string): RouteLocationRaw {
    if (route.name?.toString().includes('customer')) {
        return {
            name: 'packaging.customers.transactions.show',
            params: { ...route.params, transactionId: id },
            query: route.query,
        }
    }

    return {
        name: 'packaging.transactions.show',
        params: { ...route.params, transactionId: id },
        query: route.query,
    }
}

function receiptLink(id: string): RouteLocationRaw {
    if (route.name?.toString().includes('customer')) {
        return {
            name: 'pod.customers.receipts.show',
            params: { ...route.params, receiptId: id },
            query: route.query,
        }
    }

    return {
        name: 'pod.receipts.show',
        params: { ...route.params, receiptId: id },
    }
}

function print() {
    printElement(modalContent.value!)
}

useMittListener('fetchTransactions', fetchTransaction)
onBeforeMount(fetchTransaction)
watch(() => props.id, fetchTransaction)
watch(() => route.params.transactionId, fetchTransaction)
</script>

<template>
    <div>
        <MyModal :value="props.open" :max-width="largeLayout ? 1200 : 600" @close="onClose">
            <LoaderWrapper :visible="loading" />

            <div v-if="transaction" ref="modalContent">
                <div class="flex justify-between">
                    <ContentHeading class="text-xl font-bold">
                        {{ t(heading) }}

                        <span
                            v-if="transaction.deletedAt"
                            v-tooltip="t('deletedTransactionExplanation')"
                            class="ml-2 font-bold text-red-500"
                        >
                            ({{ t('deleted') }})
                        </span>
                    </ContentHeading>

                    <div class="space-x-2">
                        <MyButton icon plain scheme="light" size="small" @click="print()">
                            <mdi:printer />
                        </MyButton>
                        <MyButton
                            v-if="
                                !!authStore.user &&
                                !authStore.isReadonly(LicenseType.PackagingModuleReadonly) &&
                                authStore.hasPermission(PermissionType.ManageTransactions)
                            "
                            v-tooltip="t('shareEntity', { entity: t('transaction') })"
                            icon
                            plain
                            scheme="light"
                            size="small"
                            @click="shareTransactionOpen = true"
                        >
                            <mdi:share />
                        </MyButton>
                    </div>
                </div>

                <p class="print:mb-[80px]">
                    <span class="font-semibold" v-text="t('transactionId')" />
                    {{ transaction.id }}
                </p>

                <section
                    :class="{
                        'grid-cols-2 gap-6 lg:grid': largeLayout,
                    }"
                    class="mt-6"
                >
                    <img
                        class="qrcode absolute top-0 right-0 ml-auto hidden print:block"
                        style="width: 120px; height: 120px"
                    />
                    <section class="space-y-6">
                        <NilTradeWarning v-if="isNilTrade" class="mt-5" />
                        <MyPanel v-else bordered panel-class="overflow-hidden">
                            <table class="w-full">
                                <thead>
                                    <tr
                                        class="border-b border-primary-200 bg-primary-100 text-xs dark:border-dark-400 dark:bg-dark-600"
                                    >
                                        <th
                                            class="p-2.5 font-semibold"
                                            colspan="2"
                                            v-text="t('name')"
                                        />
                                        <th
                                            class="w-20 border-x border-primary-200 p-2.5 font-semibold dark:border-dark-400"
                                            v-text="t('delivered')"
                                        ></th>
                                        <th
                                            class="w-20 p-2.5 font-semibold"
                                            v-text="t('received')"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="product in transaction.products"
                                        :key="product.id"
                                        class="border-t border-primary-200 first:border-t-0 even:bg-primary-100 dark:border-dark-400 dark:even:bg-dark-600"
                                    >
                                        <td class="w-[60px]">
                                            <img
                                                :src="imageHelper(product.image, 'thumbnail')"
                                                :alt="product.name"
                                            />
                                        </td>
                                        <td
                                            class="border-r border-primary-200 p-2.5 dark:border-dark-400"
                                            v-text="product.name"
                                        />
                                        <td
                                            class="border-r border-primary-200 text-center dark:border-dark-400"
                                        >
                                            {{ product.delivered }}
                                            <span
                                                v-if="product.deliveredFilled"
                                                class="mb-0.5 ml-2 inline-block h-2 w-2 rounded-full bg-blue-500"
                                            />
                                        </td>
                                        <td class="text-center">
                                            {{ product.received }}
                                            <span
                                                v-if="product.receivedFilled"
                                                class="mb-0.5 ml-2 inline-block h-2 w-2 rounded-full bg-blue-500"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </MyPanel>

                        <div>
                            <div
                                v-if="authStore.hasLicense(LicenseType.Filled)"
                                class="-mt-4 flex items-center"
                            >
                                <div class="mr-2 h-2 w-2 rounded-full bg-blue-500" />
                                {{ t('filledPackaging') }}
                            </div>
                        </div>

                        <div class="space-y-6">
                            <div class="space-y-6 print:flex print:space-y-0">
                                <MyCompanyCard
                                    v-if="transaction.destinationLocation"
                                    :title="t('destination')"
                                    :company="transaction.destinationCompany"
                                    :location="transaction.destinationLocation"
                                />

                                <MyCompanyCard
                                    :title="
                                        transaction.type === TransactionType.Load ||
                                        transaction.type === TransactionType.Unload
                                            ? t('contractor')
                                            : t('receiver')
                                    "
                                    :company="transaction.receivingCompany"
                                    :location="transaction.receivingLocation"
                                    :show-user="
                                        !transaction.confirmedAt ||
                                        !!transaction.receivingUser ||
                                        !!transaction.details.receiverName
                                    "
                                    :user-title="t('approvedBy')"
                                    :user="{
                                        image:
                                            transaction.receivingUser?.profileImage ??
                                            transaction.details.receiverSignature ??
                                            undefined,
                                        name:
                                            transaction.receivingUser?.name ??
                                            transaction.details.receiverName ??
                                            t('awaitingUser'),
                                        date: transaction.confirmedAt,
                                    }"
                                />

                                <MyCompanyCard
                                    :title="
                                        transaction.type === TransactionType.Load ||
                                        transaction.type === TransactionType.Unload
                                            ? t('haulier')
                                            : t('sender')
                                    "
                                    :company="transaction.sendingCompany"
                                    :location="transaction.sendingLocation"
                                    :show-user="!transaction.haulierCompany"
                                    :user-title="t('deliveredBy')"
                                    :user="{
                                        image: transaction.sendingUser.profileImage,
                                        name: transaction.sendingUser.name,
                                        date: transaction.transpiredAt,
                                    }"
                                />
                            </div>

                            <div class="space-y-6 print:flex print:space-y-0">
                                <MyCompanyCard
                                    v-if="transaction.haulierCompany && transaction.haulierLocation"
                                    :title="t('haulier')"
                                    :company="transaction.haulierCompany"
                                    :location="transaction.haulierLocation"
                                    show-user
                                    :user-title="t('deliveredBy')"
                                    :user="{
                                        image: transaction.sendingUser.profileImage,
                                        name: transaction.sendingUser.name,
                                        date: transaction.transpiredAt,
                                    }"
                                />

                                <MyCompanyCard
                                    v-if="transaction.shipperCompany && transaction.shipperLocation"
                                    :title="t('shipperLocationCompany')"
                                    :company="transaction.shipperCompany"
                                    :location="transaction.shipperLocation"
                                />
                            </div>

                            <div v-if="transaction.revisions.length > 1" class="print:hidden">
                                <h3 class="font-semibold uppercase text-primary-400">
                                    {{ t('revisions') }}
                                </h3>

                                <div v-for="revision in transaction.revisions" :key="revision.id">
                                    <RouterLink
                                        :class="{
                                            'text-blue-500 hover:text-blue-400':
                                                revision.id !== transaction.id,
                                        }"
                                        :to="transactionLink(revision.id)"
                                    >
                                        {{ datetime(revision.createdAt) }}
                                        <span>- {{ t('createdBy') }}</span>
                                        <span class="ml-1 font-semibold">
                                            {{ revision?.createdByUser.name || t('unknown') }}
                                        </span>
                                        <span
                                            v-if="revision.deletedAt"
                                            v-tooltip="datetime(revision.deletedAt)"
                                            class="ml-1.5 text-red-500 font-semibold"
                                            v-text="t('deleted').toLowerCase()"
                                        />
                                        <span
                                            v-if="revision.id === transaction.id"
                                            v-text="`(${t('current')})`"
                                        />
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section v-if="largeLayout" class="space-y-6">
                        <MapComponent
                            v-if="transaction.location"
                            :markers="[{ location: transaction.location }]"
                            class="h-[250px] w-full break-inside-avoid rounded-xl bg-green-500 shadow-xl print:shadow-none overflow-hidden"
                        />

                        <div class="space-y-3">
                            <ModalAttachment
                                v-if="transaction.destinationTransaction"
                                class="print:hidden"
                            >
                                <template #title>
                                    <div class="flex items-center gap-2 mb-2">
                                        <h3
                                            class="text-sm font-semibold uppercase text-primary-400"
                                        >
                                            {{ t('destinationTransaction') }}
                                        </h3>
                                        <mdi:alert-circle-outline
                                            v-if="
                                                transaction.destinationTransaction.state ===
                                                TransactionState.IncomingDraft
                                            "
                                            v-tooltip="t('notConfirmed')"
                                            class="cursor-help text-yellow-500"
                                        />
                                        <mdi:clipboard-alert-outline
                                            v-if="transaction.transitProductDifference"
                                            v-tooltip="t('transitProductDifferenceExplanation')"
                                            class="mr-2 text-red-500 text-base"
                                        />
                                    </div>
                                </template>

                                <template #content>
                                    <RouterLink
                                        :to="transactionLink(transaction.destinationTransaction.id)"
                                        class="font-semibold uppercase"
                                        v-text="t('view')"
                                    />
                                </template>
                            </ModalAttachment>

                            <ModalAttachment
                                v-if="transaction.transitTransaction"
                                class="print:hidden"
                            >
                                <template #title>
                                    <div class="flex items-center gap-2 mb-2">
                                        <h3
                                            class="text-sm font-semibold uppercase text-primary-400"
                                        >
                                            {{ t('transitTransaction') }}
                                        </h3>
                                        <mdi:clipboard-alert-outline
                                            v-if="transaction.transitProductDifference"
                                            v-tooltip="t('transitProductDifferenceExplanation')"
                                            class="mr-2 text-red-500 text-base"
                                        />
                                    </div>
                                </template>

                                <template #content>
                                    <RouterLink
                                        :to="transactionLink(transaction.transitTransaction.id)"
                                        class="font-semibold uppercase"
                                        v-text="t('view')"
                                    />
                                </template>
                            </ModalAttachment>

                            <ModalAttachment
                                v-for="link in transaction.linkedTransactions"
                                :key="link.transactionId"
                                class="print:hidden"
                            >
                                <template #title>
                                    <h3 class="text-sm font-semibold uppercase text-primary-400">
                                        <template
                                            v-if="
                                                link.transactionId === transaction.id &&
                                                link.type ===
                                                    LinkedTransactionType.MirroredHubTransaction
                                            "
                                        >
                                            {{ t('mirroredHubTransaction') }}
                                        </template>
                                        <template v-else>{{ t('linkedTransaction') }}</template>
                                    </h3>
                                </template>

                                <template #content>
                                    <RouterLink
                                        v-if="link.transactionId === transaction.id"
                                        :to="transactionLink(link.linkedTransactionId)"
                                        class="font-semibold uppercase"
                                        v-text="t('view')"
                                    />

                                    <RouterLink
                                        v-else
                                        :to="transactionLink(link.transactionId)"
                                        class="font-semibold uppercase"
                                        v-text="t('view')"
                                    />
                                </template>
                            </ModalAttachment>

                            <ModalAttachment
                                v-if="transaction.podReceiptId"
                                class="print:hidden"
                                :title="t('podReceipt')"
                            >
                                <template #content>
                                    <RouterLink
                                        :to="receiptLink(transaction.podReceiptId)"
                                        class="font-semibold uppercase"
                                        v-text="t('view')"
                                    />
                                </template>
                            </ModalAttachment>

                            <ModalAttachment
                                v-if="transaction.details.shippingCode"
                                :title="t('shippingCode')"
                                :content="transaction.details.shippingCode"
                            />

                            <ModalAttachment
                                v-if="transaction.details.palletReceiptNumber"
                                :title="t('palletReceiptNumber')"
                                :content="transaction.details.palletReceiptNumber"
                            />

                            <ModalAttachment
                                v-if="transaction.details.trailerNumber"
                                :title="t('trailerNumber')"
                                :content="transaction.details.trailerNumber"
                            />

                            <ModalAttachment
                                v-if="transaction.details.truckNumber"
                                :title="t('truckNumber')"
                                :content="transaction.details.truckNumber"
                            />

                            <ModalAttachment
                                v-if="transaction.details.note"
                                :title="t('notes')"
                                :content="transaction.details.note"
                                break
                            />

                            <div
                                v-if="authStore.isLoggedIn"
                                class="flex justify-between text-sm print:hidden"
                            >
                                <h3
                                    class="flex flex-shrink-0 items-center font-semibold uppercase text-primary-400"
                                    :class="{
                                        'w-full justify-between':
                                            !transaction.companyDetails.economy,
                                    }"
                                >
                                    {{ t('economyNote') }}

                                    <MyButton
                                        v-if="transactionStore.canEditTransaction(transaction)"
                                        v-tooltip="t('edit')"
                                        icon
                                        class="text-gray-600 dark:text-primary-50"
                                        @click="updateEconomyModalOpen = true"
                                    >
                                        <mdi:pencil />
                                    </MyButton>
                                </h3>

                                <div
                                    class="flex w-full items-center justify-end text-gray-600 dark:text-primary-50"
                                >
                                    {{ transaction.companyDetails.economy }}
                                </div>
                            </div>
                        </div>

                        <Disclosure
                            v-if="transaction.images.length > 0"
                            as="div"
                            default-open
                            class="break-inside-avoid"
                        >
                            <h3
                                class="hidden font-semibold uppercase text-primary-400 print:mb-2 print:block"
                                v-text="t('images')"
                            />
                            <DisclosureButton
                                class="mb-2 flex w-full justify-between rounded-lg bg-primary-200 px-4 py-2 text-left text-sm font-semibold uppercase text-primary-400 transition hover:bg-primary-300 dark:bg-dark-400 dark:text-primary-300 dark:hover:bg-dark-300"
                            >
                                {{ t('images') }}
                            </DisclosureButton>
                            <DisclosurePanel>
                                <MyImageViewer class="grid grid-cols-4 gap-3">
                                    <div
                                        v-for="image in transaction.images"
                                        :key="image.id"
                                        class="relative flex cursor-pointer items-center justify-center overflow-hidden rounded-xl bg-primary-300"
                                    >
                                        <div
                                            v-if="image.note"
                                            v-tooltip="image.note"
                                            class="absolute top-0 right-0 flex h-6 w-6 items-center justify-center rounded-bl-lg bg-primary-300 text-xs text-primary-50 shadow-lg"
                                        >
                                            <mdi:comment />
                                        </div>
                                        <img
                                            class="min-h-full w-auto object-cover"
                                            :src="imageHelper(image.path!, 'thumbnail')"
                                            :alt="image.note || ' '"
                                            :data-src="imageHelper(image.path!, 'public')"
                                        />
                                        <div
                                            class="pointer-events-none absolute bottom-0 left-0 w-full bg-primary-500/30 py-1 text-center text-sm uppercase text-white backdrop-blur-lg"
                                        >
                                            <span
                                                v-if="image.type === TransactionImageType.Delivered"
                                                v-text="t('delivered')"
                                            />
                                            <span v-else v-text="t('received')" />
                                        </div>
                                    </div>
                                </MyImageViewer>
                            </DisclosurePanel>
                        </Disclosure>

                        <Disclosure
                            v-if="transaction.consignmentNotes.length > 0"
                            as="div"
                            default-open
                        >
                            <DisclosureButton
                                class="mb-2 flex w-full justify-between rounded-lg bg-primary-200 px-4 py-2 text-left text-sm font-semibold uppercase text-primary-400 transition hover:bg-primary-300 dark:bg-dark-400 dark:text-primary-300 dark:hover:bg-dark-300"
                            >
                                {{ t('consignmentNotes') }}
                            </DisclosureButton>
                            <DisclosurePanel>
                                <MyImageViewer class="grid grid-cols-4 gap-3">
                                    <template
                                        v-for="consignmentNote in transaction.consignmentNotes"
                                        :key="consignmentNote.id"
                                    >
                                        <a
                                            v-if="
                                                consignmentNote.name?.includes('.pdf') ||
                                                consignmentNote.path?.includes('.pdf')
                                            "
                                            class="relative flex flex-col items-center justify-center overflow-hidden rounded-xl bg-primary-300"
                                            :href="asset(consignmentNote.path!)"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span
                                                class="flex min-h-[5rem] grow items-center justify-center text-center text-3xl text-red-400"
                                            >
                                                <mdi:file-pdf-box />
                                            </span>

                                            <div
                                                v-truncate-tooltip
                                                class="mb-1 w-full flex-shrink-0 truncate px-3 text-center"
                                                v-text="consignmentNote.name"
                                            />
                                        </a>

                                        <img
                                            v-else
                                            class="relative flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded-xl bg-primary-300 object-cover"
                                            :src="imageHelper(consignmentNote.path!, 'thumbnail')"
                                            :alt="consignmentNote.name"
                                            :data-src="imageHelper(consignmentNote.path!, 'public')"
                                        />
                                    </template>
                                </MyImageViewer>
                            </DisclosurePanel>
                        </Disclosure>
                    </section>
                </section>
                <img
                    :src="localAsset('assets/begreenprintlabel.png')"
                    alt="begreen"
                    class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
                />

                <MyDispatchLog
                    v-if="transaction.dispatches.length > 0"
                    :dispatches="transaction.dispatches"
                />
            </div>

            <h1
                v-if="!transaction && !loading"
                class="my-auto text-center text-4xl font-bold"
                v-text="t('transactionNotFound')"
            />
        </MyModal>

        <BasicShareModal
            v-if="transaction"
            v-model="shareTransactionOpen"
            :entity="t('transaction')"
            :endpoint="shareEndpoint"
            :customer-company-id="
                transaction.receivingCompany.id === authStore.companyId
                    ? transaction.sendingCompany.id
                    : transaction.receivingCompany.id
            "
        />

        <UpdateEconomyModal
            v-if="transaction && authStore.isLoggedIn"
            v-model="updateEconomyModalOpen"
            :transaction-id="transaction.id"
            :economy="transaction.companyDetails.economy ?? ''"
        />
    </div>
</template>
